@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@400;500;700;900&display=swap');

$bg: #042440;
$bg-secondary: #9db6b11f;
$text: #C8CBCA;
$text-heading: #FFFFFF;
$text-btn: #ffffff;
$btn-transition: 0.2s;
$btn-hover-opacity: 0.8;
$container-max-width: 1200px;

$bg-theme: #268675;

$mobileBP: 1000px;

html {
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

body {
    background: $bg;
    color: $text;
    font-family: 'Roboto', sans-serif;
}

#app.dark {
   background-color: $bg-theme;
}

p {
    font-size: 20px;
    font-weight: 500;
}

a {
    transition: 0.2s;
    text-decoration: none;
}

*::-webkit-scrollbar {
    display: none;
}

.container {
    max-width: $container-max-width;
    margin: 0 auto;
    padding: 20px 45px;

    @media (max-width: $mobileBP) {
        padding: 20px 15px;
    }
}

.section-title {
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    font-variant: small-caps;
    text-transform: uppercase;
    color: $text-heading;
}

.button {
    padding: 20px 30px;
    background-color: $bg-secondary;
    border-radius: 50px;
    color: $text-btn;
    font-weight: 800;
    font-size: 1.4rem;
    text-transform: uppercase;

    &:hover {
        opacity: $btn-hover-opacity;
    }
}

.divider {
    display: flex;
    justify-content: center;
}

.navbar {
    .navbar-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 20px;
        border-radius: 32px;

        .burgermenu {
            display: none;

            @media (max-width: $mobileBP) {
                display: block;
            }
        }
    
        .logo {
            display: flex;
            align-items: center;

            img {
                height: 60px;
            }

            h1 {
                margin-left: 20px;
                font-size: 2rem;
                font-weight: 900;
                color: $text-heading;
            }
        }
    
        .links {
            font-size: 1.3rem;
            display: flex;

            a {
                margin-left: 2rem;
                transition: 0.2s;
                color: $text-heading;
                font-weight: 500;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        @media (max-width: $mobileBP) {
            .links {
                display: none;
            }
        }
    }

    #mobileNav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: $bg;
        display: none;
        opacity: 0;
        flex-direction: column;
        justify-content: center;

        .button {
            margin: 10px 20px;
            padding: 30px;
            text-align: center;
            background-color: $bg-secondary;
            border-radius: 50px;
            color: $text-btn;
            font-weight: 800;
            font-size: 1.4rem;
            text-transform: uppercase;

            &:hover {
                opacity: $btn-hover-opacity;
            }
        }

        &.visible {
            animation: appear 0.3s ease 0s 1 forwards;
            display: flex;
        }

        @keyframes appear {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
}

.landing {
    header {
        margin: 4.5rem 0 6rem 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .graphic {
            height: 320px;
        }

        .box {
            text-align: end;
        }
        
        h1 {
            font-style: normal;
            font-weight: 900;
            font-size: 5rem;
            text-transform: uppercase;
            color: $text-heading;
            text-align: end;
            margin-bottom: 1rem;
        }

        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 3rem;
            text-transform: uppercase;
            color: $text-heading;
            margin: 1rem 0;
            margin-bottom: 3rem;
        }
       
        @media (max-width: $mobileBP) {
            margin: 0;
            justify-content: end;

            .graphic {
                width: 100%;
            }

            .box {
                width: 100%;
            }

            h1 {
                font-size: 3rem;
                margin: 0;
            }

            h2 {
                font-size: 2rem;
            }
        }
    }

    #features {
        .row {
            display: flex;
            margin: 3rem 0;

            .content {
                display: flex;
                flex-direction: column;
            }

            .graphic {
                height: 280px;
                margin-right: 2rem;

                @media (max-width: $mobileBP) {
                    display: none;
                }
            }


            .title {
                font-size: 2rem;
                font-weight: 900;
                color: $text-heading;
                margin-bottom: 1rem;
                text-transform: uppercase;
            }

            .description {
                width: 70%;
            }

            &.right{
                flex-direction: row-reverse;
                
                .content {
                    align-items: flex-end;
                }

                .graphic {
                    margin-right: 0;
                    margin-left: 2rem;
                }

                .title, .description {
                    text-align: end;
                }

            }

            @media (max-width: $mobileBP) {
                flex-wrap: wrap;

                .description {
                    width: 100%;
                }

                .title {
                    margin-bottom: 0;
                }
            }
        }
    }

    #projects {
        margin-top: 4rem;

        .project {
            display: flex;
            margin-top: 4rem;

            .preview {
                width: 700px;
                height: 600px;
                object-fit: contain;
                margin-right: 2rem;
            }

            .info {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            .title {
                font-size: 2rem;
                font-weight: 900;
                color: $text-heading;
                margin-bottom: 1rem;
                text-transform: uppercase;
                text-align: end;
            }

            .description {
                text-align: end;
                font-size: 1.5rem;
            }

            &.right {
                flex-direction: row-reverse;

                .preview {
                    margin-right: 0;
                    margin-left: 2rem;
                }

                .info {
                    align-items: flex-start;
                }

                .title, .description {
                    text-align: start;
                }
            }

            @media (max-width: $mobileBP) {
                flex-wrap: wrap;
                
                .preview {
                    width: 100%;
                    margin-right: 0;
                    margin-left: 0;
                    height: auto;
                } 

                .description {
                    text-align: center !important;
                }
            }
        }
    }

    #team {
        margin-bottom: 5rem;

        .title {
            font-size: 2rem;
            font-weight: 900;
            color: $text-heading;
            margin-bottom: 2rem;
            text-transform: uppercase;
        }

        .members {
            display: flex;
            justify-content: start;
            flex-wrap: wrap;
        }

        .member {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: space-between;
            background-color: $bg-secondary;
            border-radius: 10px;
            padding: 15px;
            transition: 0.1s;
            margin: 0.5rem;
            width: 100%;
            max-width: 45%;

            .avatar {
                height: 250px;
                width: 250px;
                object-fit: cover;
                border-radius: 10px;
                box-shadow: #ffffff75 1px 1px 10px;
            }

            .info {
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: flex-start;
                margin-left: 2rem;
                width: 100%;
            }

            .links {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            
                .link {
                    padding: 20px;
                    background-color: rgba(0, 0, 0, 0.527);
                    margin: 10px;
                    border-radius: 10px;
                }
            }

            .name {
                font-size: 1.8rem;
                font-weight: 700;
                color: $text-heading;
                margin-bottom: 0.2rem;
                text-align: start;
            }

            .function {
                text-align: center;
                color: $text-btn;
                margin: 0;
                color: rgb(170, 169, 169);
            }

            &:hover {
                transform: scale(1.01);
            }

            @media (max-width: $mobileBP) {
                width: 100%;
                max-width: 100%;
                margin-bottom: 2rem;
                flex-wrap: wrap;
                justify-content: center;

                .info {
                    margin-left: 0;
                    justify-content: center;
                }

                .name {
                    text-align: center;
                    margin: 2rem auto 0 auto;
                }

                .function {
                    text-align: center;
                    margin: 1rem auto;
                }
            }
            
        }
    }

    #contact {
        .title {
            font-size: 2rem;
            font-weight: 900;
            color: $text-heading;
            margin-bottom: 1rem;
            text-transform: uppercase;
        }

        .row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .button {
                margin: 10px;
                width: 100%;
                max-width: 200px;
                text-align: center;

                &:first-of-type {
                    margin-left: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                }

                @media (max-width: 1200px) {
                    &:first-of-type {
                        margin-left: 10px;
                    }
    
                    &:last-of-type {
                        margin-right: 10px;
                    }
                }

                @media (max-width: $mobileBP) {
                    max-width: 100%;
                }
            }
        }
    }
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
        margin: 10px 0;
        width: 80%;
        text-align: center;
    }
}

footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 3rem;

    .group {
        display: flex;
    }
    
    .icons {
        .link {
            all: unset;
            margin: 7px;

            img {
                height: 20px;
            }

            &:hover {
                opacity: $btn-hover-opacity;
            }
        }
    }

    .links {
        margin-left: 30px;

        .link {
            margin: 7px;
            color: $text-heading;

            &:hover {
                opacity: $btn-hover-opacity;
            }
        }

        @media (max-width: $mobileBP) {
            margin: 0;
        }
    }

    @media (max-width: $mobileBP) {
        justify-content: center;

        .group {
            margin-bottom: 1rem;
        }
    }
}